<template>
  <div class="home">
    <div class="left">
      <el-image :src="img" fit="cover" style="width: 100%; height: 100%;" />
    </div>

    <div class="right">
      <div class="language">
        <div style="max-width: 600px; min-width: 500px; width: 60%; box-sizing: border-box; padding: 0 10px; display: flex; flex-direction: row; align-items: center; justify-content: space-between;">
          <el-image :src="boao" fit="fill" style="width: 213px; height: 82px;" />

          <el-radio-group
            v-model="selectLanguage"
            size="large"
            text-color="#ffb200"
            fill="#0d2d84"
            @change="onLanguageChanged"
          >
            <el-radio-button
              v-for="item of languages"
              :key="item.code"
              :label="item.code"
              :class="item.code"
            >
              {{ item.name }}
            </el-radio-button>
          </el-radio-group>
        </div>
      </div>

      <div class="login-container">
        <div class="login">
          <div>
            <div class="company-name">博鳌亚洲论坛酒店预订系统</div>
            <div class="company-en-name">Boao Forum For Asia Hotel Reservation Service</div>
          </div>
          <div v-if="language === 'zh'" class="login-field">
            <div>{{ $t('lastName') }}<span style="color: red;">（请输入拼音）</span></div>
            <el-input
              size="large"
              type="text"
              v-model="last"
              @blur="last = last.trim()"
            />
          </div>

          <div v-if="language === 'zh'" class="login-field">
            <div>{{ $t('firstName') }}<span style="color: red;">（请输入拼音）</span></div>
            <el-input
              size="large"
              type="text"
              v-model="first"
              @blur="first = first.trim()"
            />
          </div>

          <div v-if="language === 'en'" class="login-field">
            <div>{{ $t('firstName') }}</div>
            <el-input
              size="large"
              type="text"
              v-model="first"
              @blur="first = first.trim()"
            />
          </div>

          <div v-if="language === 'en'" class="login-field">
            <div>{{ $t('lastName') }}</div>
            <el-input
              size="large"
              type="text"
              v-model="last"
              @blur="last = last.trim()"
            />
          </div>

          <div class="login-field">
            <div>{{ $t('invitationCode') }}</div>
            <el-input
              size="large"
              type="text"
              v-model="invitationCode"
              @blur="invitationCode = invitationCode.trim()"
            />
          </div>

          <el-button
            class="login-button"
            @click="login"
            :loading="loading"
          >
            {{ $t('login') }}
          </el-button>
        </div>
      </div>

      <div class="footer">
        <div style="display: flex; flex-direction: row; align-items: center; justify-content: center;">
          <div>
            <img src="@/assets/dongfangmeijing.png" />
          </div>
          <div style="font-size: 20px;">
            <div>{{ $t('for more information') }}</div>
            <div style="font-size: 14px;">
              <span>{{ $t('footer customer tel') }}: +86-400-098-2996</span>
              <span style="padding-left: 10px;">Email: hotel@bjome.com.cn</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <van-popup v-model="showService" closeable :style="{ width: '500px' }">
      <div style="padding: 2.5em 1.4em;">
        <van-row type="flex">
          <van-col span="5" style="background: #eee; display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <div>
              <van-icon name="warning-o" size="40" color="orange" />
            </div>
            <div>
              {{ $t('sorry') }}
            </div>
          </van-col>
          <van-col span="19" style="padding: 0 0 0 10px;">
            <div class="word">{{ $t('login notify') }}</div>
            <div class="word">
              <span>{{ $t('customer tel') }}: </span>
              <span class="important-text">+86-400-098-2996</span>
            </div>
            <div class="word">
              <span>{{ $t('customer email') }}: </span>
              <span class="important-text">hotel@bjome.com.cn</span>
            </div>
          </van-col>
        </van-row>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Notify } from 'vant'
import { mapState } from 'vuex'
import { vanLocales } from '@/lang'

export default {
  name: 'Home',
  components: {

  },
  data () {
    return {
      img: require('@/assets/left.png'),
      boao: require('@/assets/boao.png'),
      selectLanguage: this.$store.state.language,
      languages: [
        { code: 'zh', name: '中文' },
        { code: 'en', name: 'English' }
      ],
      last: '',
      first: '',
      invitationCode: '',
      showService: false,
      loading: false
    }
  },
  computed: {
    ...mapState({
      language: state => state.language
    })
  },
  methods: {
    onLanguageChanged (value) {
      this.$i18n.locale = value
      vanLocales(value)
      this.$store.dispatch('setLanguage', value)
    },
    check () {
      if (!this.last || !this.last.trim()) {
        return false
      }

      if (!this.first || !this.first.trim()) {
        return false
      }

      if (!this.invitationCode || !this.invitationCode.trim()) {
        return false
      }

      return true
    },
    async login () {
      // check user input
      if (this.check()) {
        // backend login
        try {
          this.loading = true
          const res = await this.$axios.post('/invitation/login', {
            last: this.last.trim(),
            first: this.first.trim(),
            invitationCode: this.invitationCode.trim()
          })

          this.$store.dispatch('setToken', res.token)
          this.$router.push('hotel-list')
        } catch (e) {
          Notify(e.message)
          console.error(e)
        } finally {
          this.loading = false
        }
      } else {
        this.showService = true
      }
    }
  }
}
</script>

<style lang="less" scoped>
@blue:#0d2d84;
@gold:#ffb200;

.home {
  font-family: "Microsoft YaHei Bold", "Microsoft YaHei", Arial, Avenir, Helvetica, sans-serif;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-height: 100%;
}

.left {
  width: 56%;
  overflow: hidden;
}

.right {
  flex: 1;
  display: flex;
  flex-direction: column;
  .language {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 180px;
  }

  .login-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .company-name, .company-en-name {
      font-size: 2.5em;
      color: @blue;
      font-weight: bold;
    }

    .company-en-name {
      font-size: 1.3em;
      font-weight: unset;
    }

    .login {
      color: #636363;
      box-sizing: border-box;
      max-width: 600px;
      min-width: 500px;
      width: 60%;
      padding: 40px 10px 0 10px;
    }
  }
}

.login-button {
  width: 100%;
  height: 53px;
  border-radius: 10px;
  margin-top: 50px;
  background-color: @blue;
  color: @gold;
  font-size: 1.5em;
}

.footer {
  color: #9e9e9e;
  font-size: 15px;
  padding-bottom: 45px;
  span {
    padding-right: 15px;
  }
}

.link {
  font-family: "Microsoft YaHei Bold", "Microsoft YaHei", Arial, Avenir, Helvetica, sans-serif !important;
  font-size: 15px;
  color: #9e9e9e;
}

.login-field {
  font-weight: 500;
  margin-top: 30px;
  div:first-child {
    box-sizing: border-box;
    padding-bottom: 8px;
    height: 30px;
  }
}
</style>
